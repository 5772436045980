import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './unsubscribe.scss';
import { Link } from 'gatsby';
import { SecondaryButton } from '@entur/button';
import { GridContainer, GridItem } from '@entur/grid';
import { SubscribeErrorProvider } from './ToastErrorContext';
import { UnsubscribeButton } from './UnsubscribeButton';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "unsubscribe"
    }}>{`Unsubscribe`}</h1>
    <SubscribeErrorProvider mdxType="SubscribeErrorProvider">
    <GridContainer spacing="medium" rowSpacing="large" mdxType="GridContainer">
        <GridItem small={12} mdxType="GridItem">
            If you unsubscribe you may resubscribe at any time by going back to{' '}
            <a href="/pages-intro-news">News and Notifications</a> and click on
            "Subscribe".
        </GridItem>
        <GridItem small={12} mdxType="GridItem">
            If you have any feedback on how to improve this service, please send
            it to us by clicking on the "Feedback" button in the bottom right
            corner of the page.
        </GridItem>
        <GridItem small={12} mdxType="GridItem">
            <SecondaryButton as={Link} to="/pages-intro-news" className="unsubscribe-button-margin" mdxType="SecondaryButton">
                Cancel
            </SecondaryButton>
            <UnsubscribeButton mdxType="UnsubscribeButton" />
        </GridItem>
    </GridContainer>
    </SubscribeErrorProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      